import React from 'react';

const Footer = () => {
    return (
        <footer>
            <p>&copy; 2024 Pramodni & Co. | All Rights Reserved</p>
            <p>Plot Number 4, First Floor, Hasanpur Village, I.P. Ext. Patparganj, Near Blinkit, New Delhi-110092</p>
        </footer>
    );
};

export default Footer;

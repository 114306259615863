import React from 'react';
import './About.css'; 

const About = () => {
    return (
        <section className="about-section">
            <div className="container">
                <h2>About Us</h2>
                
                <div className="about-content">
                    <div className="about-text">
                        <h3>Our Mission</h3>
                        <p>
                            We are a dynamic team of Chartered Accountants committed to delivering the highest level of financial services. 
                            Our mission is to simplify your financial world and provide you with the peace of mind needed to focus on what matters most – 
                            your business and your future. We specialize in providing expert auditing, taxation, and consulting services.
                        </p>
                        <h3>Why Choose Us?</h3>
                        <p>
                            Our team of experienced professionals offers personalized solutions to help businesses thrive. 
                            With years of industry knowledge, we offer services tailored to your unique needs, helping you stay compliant, 
                            manage your finances, and grow your business.
                        </p>
                    </div>
                    <div className="about-image">
                        <img src="/images/teamwork.jpg" alt="Teamwork" />
                    </div>
                </div>

                <div className="values-section">
                    <h3>Our Core Values</h3>
                    <div className="values">
                        <div className="value-item">
                            <i className="fas fa-balance-scale"></i>
                            <h4>Integrity</h4>
                            <p>We believe in honesty and transparency in every aspect of our work.</p>
                        </div>
                        <div className="value-item">
                            <i className="fas fa-user-shield"></i>
                            <h4>Confidentiality</h4>
                            <p>Your financial privacy is our top priority. We protect your data with the highest standards.</p>
                        </div>
                        <div className="value-item">
                            <i className="fas fa-chart-line"></i>
                            <h4>Growth</h4>
                            <p>We are dedicated to helping your business reach new heights with our expert advice.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;

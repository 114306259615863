import React from 'react';
import './ServiceDetails.css';

const ISORegistration = () => {
  return (
    <div className="service-page">
      <div className="service-header">
        <h1>ISO Registration Services</h1>
      </div>
      <div className="service-content">
        <p>
          ISO (International Organization for Standardization) certification is essential for businesses aiming to improve their operational efficiency, enhance customer satisfaction, and gain a competitive edge in the market. ISO standards provide a framework for establishing effective processes and quality management systems. Our firm specializes in providing comprehensive ISO registration services to help you achieve certification and demonstrate your commitment to quality and excellence.
        </p>

        <h2>Our ISO Registration Services Include:</h2>
        <ul>
          <li><strong>Pre-Assessment:</strong> We conduct a preliminary assessment of your organization's processes and practices to identify gaps and areas for improvement before the formal audit.</li>
          <li><strong>Documentation Support:</strong> Our team assists in developing the necessary documentation, including quality manuals, procedures, and work instructions, tailored to meet ISO standards.</li>
          <li><strong>Internal Audits:</strong> We conduct internal audits to evaluate your compliance with ISO requirements and prepare your team for the external audit.</li>
          <li><strong>Certification Support:</strong> We guide you through the entire certification process, coordinating with the certification body and ensuring all requirements are met.</li>
        </ul>

        <h2>Why Choose Our ISO Registration Services?</h2>
        <ul>
          <li><strong>Expert Knowledge:</strong> Our consultants have extensive experience in ISO standards and implementation, providing you with the expertise needed for a successful certification process.</li>
          <li><strong>Tailored Approach:</strong> We customize our services to fit your specific industry and organizational needs, ensuring effective solutions.</li>
          <li><strong>Efficiency and Quality:</strong> Our focus on efficiency and quality helps you streamline processes and improve overall performance.</li>
          <li><strong>Ongoing Support:</strong> We provide continuous support even after certification, helping you maintain compliance and drive continuous improvement.</li>
        </ul>

        <h2>Our Process:</h2>
        <ul>
          <li><strong>Step 1:</strong> Initial Consultation: We discuss your goals for ISO certification and assess your current practices and processes.</li>
          <li><strong>Step 2:</strong> Pre-Assessment: Our team conducts a pre-assessment to identify gaps and prepare your organization for certification.</li>
          <li><strong>Step 3:</strong> Documentation Development: We assist in creating necessary documentation aligned with ISO requirements.</li>
          <li><strong>Step 4:</strong> Internal Audit: We perform internal audits to ensure compliance and readiness for the external audit.</li>
          <li><strong>Step 5:</strong> Certification Audit: We guide you through the certification audit with a recognized certification body, ensuring all requirements are met.</li>
          <li><strong>Step 6:</strong> Post-Certification Support: We provide ongoing support for maintaining ISO certification and driving continuous improvement initiatives.</li>
        </ul>

        <h2>Achieve ISO Certification with Our Expert Services</h2>
        <p>
          Enhance your organization's credibility and operational efficiency with our comprehensive ISO registration services. Contact us today to embark on your ISO certification journey!
        </p>
      </div>
    </div>
  );
};

export default ISORegistration;

import React from 'react';
import './ServiceDetails.css'

const Income = () =>{
    return (
        <div className='service-page'>
            <div className='service-header'>
                <h1>Income Tax Filing & Consultation</h1>
            </div>
            <div className='service-content'>
            <p>
          At Pramodni & Co., we understand that income tax compliance is one of the most crucial responsibilities for individuals and businesses. With constantly evolving tax laws and complex regulations, it's essential to file accurate and timely returns. Our expert team is here to help you navigate the complexities of income tax filing, ensuring full compliance with the law while maximizing your eligible deductions and credits.
        </p>

        <h2>Our Income Tax Services Include:</h2>
        <ul>
          <li><strong>Individual Income Tax Filing:</strong> Whether you're a salaried employee, self-employed, or a freelancer, we provide tailored tax filing services to ensure you meet all the necessary requirements.</li>
          <li><strong>Business Tax Filing:</strong> We assist businesses of all sizes—startups, small businesses, partnerships, and corporations—in filing accurate and compliant income tax returns.</li>
          <li><strong>Tax Planning & Advisory:</strong> We offer year-round tax planning services, helping you make informed decisions on investments, business structures, and other financial matters to optimize your tax liability.</li>
          <li><strong>Handling Income Tax Notices:</strong> Our team will assist you in responding to any tax notices, helping you understand the issue and providing guidance on how to resolve it promptly.</li>
          <li><strong>Capital Gains Tax Computation:</strong> We help calculate capital gains tax on the sale of property, shares, and other assets, ensuring exemptions like Section 54 are utilized.</li>
          <li><strong>Assistance with Tax Refunds:</strong> We ensure you're claiming refunds accurately and promptly, and track the status to ensure smooth processing.</li>
        </ul>

        <h2>Why Choose Us?</h2>
        <ul>
          <li><strong>Expert Knowledge:</strong> Our team stays updated with the latest tax regulations, ensuring compliance and accurate tax filing.</li>
          <li><strong>Personalized Services:</strong> We provide customized tax filing solutions tailored to your specific needs, whether you're an individual or a business.</li>
          <li><strong>Confidentiality:</strong> We maintain the highest standards of confidentiality and data security.</li>
          <li><strong>Hassle-Free Filing:</strong> We take care of the entire tax filing process, saving you time and reducing stress.</li>
          <li><strong>Tax Optimization:</strong> We help you legally reduce your tax burden by identifying all possible deductions, exemptions, and credits.</li>
        </ul>

        <h2>How It Works:</h2>
        <ul>
          <li><strong>Initial Consultation:</strong> We start by understanding your income, expenses, and financial goals.</li>
          <li><strong>Documentation:</strong> We help gather all the necessary financial documents like salary slips, investment proofs, and other tax-related documents.</li>
          <li><strong>Tax Calculation & Deductions:</strong> Our team will calculate your taxable income and identify eligible deductions and exemptions.</li>
          <li><strong>Return Filing:</strong> We will prepare and file your income tax return accurately and on time.</li>
          <li><strong>Follow-Up:</strong> After filing, we monitor the status of your tax returns and refunds, ensuring everything is processed smoothly.</li>
        </ul>

        <h2>Contact Us Today for Expert Tax Help</h2>
        <p>
          Whether you need assistance with individual income tax filing or business tax compliance, we are here to help. Contact us to schedule a consultation, and let us take the burden of tax filing off your shoulders.
        </p>
      </div>
        </div>
    );
};

export default Income;
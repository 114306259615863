import React from 'react';
import './ServiceDetails.css';

const ROCAnnualFiling = () => {
  return (
    <div className="service-page">
      <div className="service-header">
        <h1>ROC/Annual Filing Services</h1>
      </div>
      <div className="service-content">
        <p>
          Filing annual returns with the Registrar of Companies (ROC) is a statutory requirement for all registered companies in India. Timely and accurate filing not only ensures compliance with the Companies Act but also maintains your company's good standing. Our expert team provides comprehensive ROC and annual filing services to help you navigate these requirements seamlessly.
        </p>

        <h2>Our ROC/Annual Filing Services Include:</h2>
        <ul>
          <li><strong>Annual Return Filing:</strong> We prepare and file your company’s annual return, ensuring all necessary details about your business activities are accurately reported.</li>
          <li><strong>Financial Statements Filing:</strong> We assist in preparing and filing your financial statements, including the balance sheet and profit & loss account, in compliance with the regulatory framework.</li>
          <li><strong>Director Report Preparation:</strong> Our team helps draft the director's report, a key document that summarizes the company's performance and is submitted alongside annual returns.</li>
          <li><strong>Compliance Management:</strong> We ensure that all necessary filings are done on time, helping you avoid penalties and legal issues related to non-compliance.</li>
        </ul>

        <h2>Why Choose Our ROC/Annual Filing Services?</h2>
        <ul>
          <li><strong>Expertise:</strong> Our experienced professionals are well-versed in the latest compliance regulations and requirements for ROC filings.</li>
          <li><strong>Timely Submissions:</strong> We monitor deadlines and ensure that your filings are submitted on time to avoid penalties and interest.</li>
          <li><strong>Tailored Solutions:</strong> We provide customized services to meet the specific needs of your business, ensuring all aspects of filing are covered.</li>
          <li><strong>Peace of Mind:</strong> With our support, you can focus on running your business while we handle your compliance obligations.</li>
        </ul>

        <h2>Our Process:</h2>
        <ul>
          <li><strong>Step 1:</strong> Initial Consultation: We discuss your company’s requirements and gather the necessary documents for filing.</li>
          <li><strong>Step 2:</strong> Preparation of Documents: Our team prepares the annual return and financial statements, ensuring accuracy and compliance.</li>
          <li><strong>Step 3:</strong> Review and Approval: We review all documents with you for approval before filing.</li>
          <li><strong>Step 4:</strong> Filing with ROC: We file the annual return and other necessary documents with the Registrar of Companies on your behalf.</li>
          <li><strong>Step 5:</strong> Post-Filing Support: We provide confirmation of filings and assist with any follow-up requirements.</li>
        </ul>

        <h2>Ensure Compliance with ROC/Annual Filing</h2>
        <p>
          Stay ahead of compliance requirements and avoid penalties with our reliable ROC and Annual Filing services. Contact us today to ensure your business remains compliant!
        </p>
      </div>
    </div>
  );
};

export default ROCAnnualFiling;

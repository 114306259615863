import React from 'react';
import './ServiceDetails.css';

const ComplianceByCompany = () => {
  return (
    <div className="service-page">
      <div className="service-header">
        <h1>Compliance by Company Services</h1>
      </div>
      <div className="service-content">
        <p>
          In today’s dynamic regulatory environment, ensuring compliance with legal and regulatory requirements is vital for every business. Compliance by company services help organizations meet their statutory obligations, reduce risks, and enhance operational efficiency. Our firm specializes in providing comprehensive compliance services tailored to your specific business needs, ensuring that you stay compliant and focused on your core operations.
        </p>

        <h2>Our Compliance by Company Services Include:</h2>
        <ul>
          <li><strong>Regulatory Compliance Assessment:</strong> We conduct a thorough assessment of your business operations to identify compliance gaps and recommend corrective measures.</li>
          <li><strong>Document Preparation and Filing:</strong> Our team prepares and files all necessary compliance documents, including annual returns, forms, and reports, with the relevant authorities.</li>
          <li><strong>Corporate Governance Advisory:</strong> We provide guidance on best practices in corporate governance, helping you establish a strong framework for compliance and accountability.</li>
          <li><strong>Training and Workshops:</strong> We conduct training sessions and workshops for your team to enhance their understanding of compliance requirements and procedures.</li>
        </ul>

        <h2>Why Choose Our Compliance by Company Services?</h2>
        <ul>
          <li><strong>Expertise:</strong> Our professionals have extensive experience in compliance management across various industries, ensuring you receive informed advice and support.</li>
          <li><strong>Tailored Solutions:</strong> We customize our services to align with your specific business operations and regulatory requirements.</li>
          <li><strong>Risk Mitigation:</strong> By proactively addressing compliance issues, we help you minimize risks and avoid potential penalties.</li>
          <li><strong>Peace of Mind:</strong> With our compliance services, you can focus on growing your business, knowing that your compliance obligations are managed by experts.</li>
        </ul>

        <h2>Our Process:</h2>
        <ul>
          <li><strong>Step 1:</strong> Initial Consultation: We discuss your business operations and compliance needs to understand your unique requirements.</li>
          <li><strong>Step 2:</strong> Compliance Assessment: Our team performs a detailed assessment to identify compliance gaps and develop a tailored action plan.</li>
          <li><strong>Step 3:</strong> Document Preparation: We prepare and organize all necessary compliance documents, ensuring accuracy and adherence to legal requirements.</li>
          <li><strong>Step 4:</strong> Filing and Submission: We handle the filing of documents with relevant authorities and ensure timely submissions.</li>
          <li><strong>Step 5:</strong> Ongoing Support: We provide continuous support and updates on compliance requirements to keep your business aligned with regulatory changes.</li>
        </ul>

        <h2>Stay Compliant with Our Expert Services</h2>
        <p>
          Ensure your business operates smoothly and remains compliant with our comprehensive Compliance by Company services. Contact us today to discuss your compliance needs!
        </p>
      </div>
    </div>
  );
};

export default ComplianceByCompany;

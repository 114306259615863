import React from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsapp, FaPhone} from 'react-icons/fa';

const Header = () => {
    const phoneNumber = "919953428224"; 
    const message = "Hello, I would like to know more about your services";

    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    return (
        <header>
            <div className='company-name'>
            <h1 className='title-header'>Pramodni & Co</h1>
            <h3>(Chartered Accountants)</h3>
            <h2>Your Trusted Financial Advisors</h2>
            </div>
            
            <nav>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li className="dropdown">
                        <Link to="/services" className="dropdown-toggle">
                            Services <span className="arrow">▼</span>
                        </Link>
                        <ul className="dropdown-menu">
                            <li><Link to="/services/income-tax">Income Tax Filing & Consultation</Link></li>
                            <li><Link to="/services/gst-registration">GST Registration & Return Filing</Link></li>
                            <li><Link to="/services/accounting">Accounting & Bookkeeping</Link></li>
                            <li><Link to="/services/company-registration">Company Incorporation</Link></li>
                            <li><Link to="/services/audit">Audit & Assurance Services</Link></li>
                            <li><Link to="/services/business-licenses">Business Licenses (GST, MSME)</Link></li>
                            <li><Link to="/services/tds">TDS Return</Link></li>
                            <li><Link to="/services/business-valuation">Business Valuation</Link></li>
                            <li><Link to="/services/digital-signature-certificates">Digital Signature Certificates</Link></li>
                            <li><Link to="/services/roc">ROC/Annual Filing</Link></li>
                            <li><Link to="/services/trademark-registration">Trademark Registration</Link></li>
                            <li><Link to="/services/compliance-by-company">Compliance by Company</Link></li>
                            <li><Link to="/services/iso-registration">ISO Registration</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/about">About Us</Link></li>
                    <li><Link to="/team">Our Team</Link></li>
                    <li><Link to="/contact">Contact Us</Link></li>
                    {/* WhatsApp Button */}
                    <li>
                        <a href={whatsappURL} target="_blank" rel="noopener noreferrer" className="whatsapp-link">
                            <FaWhatsapp style={{ marginRight: '5px' }} /> +919953428224
                        </a>
                    </li>
                    <li>
                        <a href="tel:+919953428224" className="call-btn">
                            <FaPhone style={{ marginRight: '5px' }}/>+919953428224
                        </a>
                    </li>
                    <li>
                        <a href="tel:+911147548056" className="call-btn">
                            <FaPhone style={{ marginRight: '5px' }}/>01147548056
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;

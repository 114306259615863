// import React from 'react';

// const Services = () => {
//     return (
//         <div className="services">
//             <h1>Our Services</h1>
//             <ul>
//                 <li>Income Tax Filing & Consultation</li>
//                 <li>GST Registration & Return Filing</li>
//                 <li>Accounting & Bookkeeping</li>
//                 <li>Company Incorporation</li>
//                 <li>Audit & Assurance Services</li>
//                 <li>Business Licenses (GST, MSME)</li>
//                 <li>Loan & Finance Services</li>
//                 <li>Business Valuation</li>
//                 <li>Corporate Law Consulting</li>
//             </ul>
//         </div>
//     );
// };

// export default Services;

import React from 'react';
import { Link } from 'react-router-dom';
import './Services.css';
// import accountingIcon from '../images/accounting-icon.png';
// import taxIcon from '../images/tax-icon.png';
// import auditIcon from '../images/audit-icon.png';
// import advisoryIcon from '../images/advisory-icon.png';

const Services = () => {
    return (
        <div className="services-page">
            <header className="services-header">
                <h1>Our Services</h1>
                <p>We provide a wide range of financial, legal, and consultation services tailored to your business needs.</p>
            </header>

            <section className="services-grid">
                <div className="service-card">
                    {/* <img src={taxIcon} alt="Income Tax" className="service-icon" /> */}
                    <h3>Income Tax Filing & Consultation</h3>
                    <p>Expert advice and assistance for filing your income tax returns and tax planning.</p>
                    <Link to="/services/income-tax" className="learn-more-button">Learn More</Link>
                </div>

                <div className="service-card">
                    {/* <img src={gstIcon} alt="GST Services" className="service-icon" /> */}
                    <h3>GST Registration & Return Filing</h3>
                    <p>Hassle-free GST registration and return filing for your business.</p>
                    <Link to="/services/gst-registration" className="learn-more-button">Learn More</Link>
                </div>

                <div className="service-card">
                    {/* <img src={accountingIcon} alt="Accounting & Bookkeeping" className="service-icon" /> */}
                    <h3>Accounting & Bookkeeping</h3>
                    <p>Comprehensive services to manage your business finances efficiently.</p>
                    <Link to="/services/accounting" className="learn-more-button">Learn More</Link>
                </div>

                <div className="service-card">
                    {/* <img src={incorporationIcon} alt="Company Incorporation" className="service-icon" /> */}
                    <h3>Company Incorporation</h3>
                    <p>Start your business with our easy and quick company incorporation services.</p>
                    <Link to="/services/company-registration" className="learn-more-button">Learn More</Link>
                </div>

                <div className="service-card">
                    {/* <img src={auditIcon} alt="Audit & Assurance" className="service-icon" /> */}
                    <h3>Audit & Assurance Services</h3>
                    <p>Professional audit services to ensure your company’s financial compliance.</p>
                    <Link to="/services/audit" className="learn-more-button">Learn More</Link>
                </div>

                <div className="service-card">
                    {/* <img src={licenseIcon} alt="Business Licenses" className="service-icon" /> */}
                    <h3>Business Licenses (GST, MSME)</h3>
                    <p>We help you obtain necessary business licenses like GST and MSME.</p>
                    <Link to="/services/business-licenses" className="learn-more-button">Learn More</Link>
                </div>

                <div className="service-card">
                    {/* <img src={loanIcon} alt="Loan & Finance" className="service-icon" /> */}
                    <h3>TDS Return</h3>
                    <p>We help you in easy and hassle free TDS return filing.</p>
                    <Link to="/services/tds" className="learn-more-button">Learn More</Link>
                </div>

                <div className="service-card">
                    {/* <img src={valuationIcon} alt="Business Valuation" className="service-icon" /> */}
                    <h3>Business Valuation</h3>
                    <p>Accurate valuation services to assess your company’s worth and future growth.</p>
                    <Link to="/services/business-valuation" className="learn-more-button">Learn More</Link>
                </div>

                <div className="service-card">
                    {/* <img src={lawIcon} alt="Corporate Law Consulting" className="service-icon" /> */}
                    <h3>Digital Signature Certificates</h3>
                    <p>Secure your online transactions with our reliable Digital Signature Certificates, ensuring authenticity and compliance for all your digital communications.</p>
                    <Link to="/services/digital-signature-certificates" className="learn-more-button">Learn More</Link>
                </div>
                <div className='service-card'>
                    <h3>ROC/Annual Filing</h3>
                    <p>Stay compliant with our expert ROC and Annual Filing services, ensuring timely submission of essential documents to the Registrar of Companies for your business.</p>
                    <Link to="/services/roc" className='learn-more-button'>Learn More</Link>
                </div>
                <div className='service-card'>
                    <h3>Trademark Registration</h3>
                    <p>Secure your brand's identity and protect your intellectual property with our comprehensive trademark registration services.</p>
                    <Link to="/services/trademark-registration" className='learn-more-button'>Learn More</Link>
                </div>
                <div className='service-card'>
                    <h3>Compliance by Company</h3>
                    <p>Ensure your business meets all legal obligations and operates smoothly with our expert compliance by company services.</p>
                    <Link to="/services/trademark-registration" className='learn-more-button'>Learn More</Link>
                </div>
                <div className='service-card'>
                    <h3>ISO Registration</h3>
                    <p>Achieve operational excellence and enhance credibility with our professional ISO registration services.</p>
                    <Link to="/services/iso-registration" className='learn-more-button'>Learn More</Link>
                </div>
            </section>
        </div>
    );
};

export default Services;

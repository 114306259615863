import React from 'react';
import './ServiceDetails.css';

const AuditAssurance = () => {
  return (
    <div className="service-page">
      <div className="service-header">
        <h1>Audit & Assurance Services</h1>
      </div>
      <div className="service-content">
        <p>
          At Pramodni & Co., we provide comprehensive audit and assurance services that help businesses enhance their financial credibility, manage risks, and comply with regulations. Our experienced auditors use modern methodologies and technology to deliver reliable and insightful audits.
        </p>

        <h2>Our Audit & Assurance Services Include:</h2>
        <ul>
          <li><strong>Statutory Audit:</strong> We conduct statutory audits in compliance with the regulatory requirements to ensure accurate and fair representation of financial statements.</li>
          <li><strong>Internal Audit:</strong> Our internal audit services help businesses enhance governance, streamline operations, and improve risk management.</li>
          <li><strong>Tax Audit:</strong> We conduct tax audits to ensure compliance with the Income Tax Act, verifying the accuracy of financial records for tax purposes.</li>
          <li><strong>Management Audit:</strong> We provide management audits to evaluate and improve business efficiency, internal controls, and management practices.</li>
          <li><strong>Forensic Audit:</strong> Our forensic audit services help identify fraud and irregularities, offering deep analysis to detect and prevent financial misconduct.</li>
          <li><strong>Compliance Audit:</strong> We ensure that businesses meet regulatory requirements, providing compliance audits for various industries and sectors.</li>
          <li><strong>Due Diligence:</strong> Our due diligence services provide a thorough assessment of potential business investments or acquisitions to mitigate risks and evaluate opportunities.</li>
        </ul>

        <h2>Why Choose Our Audit & Assurance Services?</h2>
        <ul>
          <li><strong>Reliable and Transparent Audits:</strong> We ensure transparency and reliability in our audit processes, delivering accurate financial reports that inspire trust among stakeholders.</li>
          <li><strong>Experienced Auditors:</strong> Our team of experienced auditors and chartered accountants has extensive knowledge in various industries, providing expertise that aligns with your business needs.</li>
          <li><strong>Risk Management Focus:</strong> We help you identify, assess, and manage risks effectively through our risk-based audit approach, ensuring business continuity and resilience.</li>
          <li><strong>Regulatory Compliance:</strong> We stay updated with the latest laws and regulations, ensuring that your business remains compliant with statutory obligations.</li>
          <li><strong>Customized Audit Approach:</strong> We understand that every business is unique, so we tailor our audit and assurance services to meet your specific needs and industry challenges.</li>
        </ul>

        <h2>Our Audit Process:</h2>
        <ul>
          <li><strong>Step 1:</strong> Initial consultation to understand your business, its operations, and financial structure, followed by defining the scope of the audit.</li>
          <li><strong>Step 2:</strong> Planning the audit based on industry standards, identifying key risks, and creating a tailored audit strategy.</li>
          <li><strong>Step 3:</strong> Performing audit procedures to verify financial data, assess internal controls, and evaluate compliance with legal requirements.</li>
          <li><strong>Step 4:</strong> Delivering a comprehensive audit report with insights and recommendations for improving business processes and financial health.</li>
        </ul>

        <h2>Enhance Your Business Credibility</h2>
        <p>
          Our audit and assurance services go beyond traditional audits; we help businesses enhance their financial standing, mitigate risks, and improve governance. Contact us today to discuss how we can assist you with your auditing and assurance needs.
        </p>
      </div>
    </div>
  );
};

export default AuditAssurance;

import React, { useState, useEffect } from 'react';
import './Clock.css'; // Optional: For custom styles

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000); // Update the time every second

    return () => clearInterval(intervalId); // Clean up on component unmount
  }, []);

  // Format time
  const formattedTime = time.toLocaleTimeString();

  return (
    <div className="clock-container">
      <h2 className="clock">{formattedTime}</h2>
    </div>
  );
};

export default Clock;

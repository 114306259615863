import React from 'react';
import { Link } from 'react-router-dom';
import Clock from './Clock';

const Home = () => {
    return (
        <div className="home">
            <section className="hero">
                <Clock />
                <h1>Simplifying Your Financial Journey</h1>
                <h2>Expert Chartered Accountant Services</h2>
                <div className='para'>
                    <h4 className='para-h4'>We offer a wide range of financial services tailored to your business needs.<br></br> 
                    Let us help you grow and succeed.</h4>
                </div>
                <Link to="/services" className="button">Explore Our Services</Link>
            </section>
            <section className="services-overview">
                <h2>Our Key Services</h2>
                <div className="services-cards">
                    <div className="card">
                        <img src='/images/tax.jpg' alt='Income tax' className='card-image'></img>
                        <Link to="/services/income-tax"><h3>Income Tax</h3></Link>
                        <p>Expert tax filing and consultation services to keep you compliant and maximize refunds.</p>
                    </div>
                    <div className="card">
                    <img src='/images/gst.jpg' alt='GST' className='card-image'></img>
                    <Link to="/services/gst-registration"><h3>GST Filing</h3></Link>
                    <p>Seamless GST registration and return filing services for your business.</p>
                    </div>
                    <div className="card">
                    <img src='/images/company.jpg' alt='Company Incorporation' className='card-image'></img>
                    <Link to="/services/company-registration"><h3>Company Incorporation</h3></Link>
                        <p>Start your business with our hassle-free incorporation services.</p>
                        
                    </div>
                    <div className="card">
                    <img src='/images/audit.jpg' alt='Audit & Assurance' className='card-image'></img>
                    <Link to="/services/audit"><h3>Audit & Assurance</h3></Link>
                        <p>Comprehensive audit services to ensure transparency and compliance.</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;

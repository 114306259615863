import React from 'react';
import './ServiceDetails.css';

const BusinessLicenses = () => {
  return (
    <div className="service-page">
      <div className="service-header">
        <h1>Business Licenses (GST, MSME)</h1>
      </div>
      <div className="service-content">
        <p>
          At Pramodni & Co., we offer comprehensive support for businesses seeking essential licenses such as GST registration and MSME (Micro, Small, and Medium Enterprises) certification. Obtaining the correct licenses is vital for businesses to operate legally and avail various benefits provided by the government.
        </p>

        <h2>Our Business Licensing Services Include:</h2>
        <ul>
          <li><strong>GST Registration:</strong> We provide end-to-end assistance for businesses to register under the Goods and Services Tax (GST) regime, ensuring compliance with GST laws and regulations.</li>
          <li><strong>MSME Registration:</strong> Our experts help small and medium-sized businesses obtain MSME certification, which provides access to various government schemes, subsidies, and incentives.</li>
          <li><strong>Shops & Establishment License:</strong> We assist in obtaining the Shops and Establishment License, which is mandatory for businesses involved in commercial activities across various states.</li>
          <li><strong>Professional Tax Registration:</strong> We help businesses and professionals register for professional tax, ensuring compliance with state-specific regulations.</li>
          <li><strong>Trade License:</strong> Our team assists in acquiring the Trade License, which allows businesses to operate in a particular locality, ensuring adherence to local laws.</li>
          <li><strong>Import Export Code (IEC):</strong> We provide services to help businesses get the Import Export Code, necessary for those engaged in international trade.</li>
        </ul>

        <h2>Why Choose Our Business Licensing Services?</h2>
        <ul>
          <li><strong>Expert Assistance:</strong> We have a dedicated team of professionals who are well-versed in regulatory requirements, ensuring that your business gets the right licenses quickly and efficiently.</li>
          <li><strong>Compliance Focus:</strong> Our services help you stay compliant with legal and regulatory obligations, avoiding penalties and legal complications.</li>
          <li><strong>Simplified Process:</strong> We streamline the application process, handling paperwork and procedural complexities so you can focus on your business operations.</li>
          <li><strong>Access to Government Benefits:</strong> Our MSME registration services help you take advantage of various government schemes, subsidies, and tax benefits designed to promote business growth.</li>
        </ul>

        <h2>How Our Process Works:</h2>
        <ul>
          <li><strong>Step 1:</strong> Consultation to understand your business, its structure, and licensing needs.</li>
          <li><strong>Step 2:</strong> Gathering the necessary documents and preparing the application for the required licenses.</li>
          <li><strong>Step 3:</strong> Submitting the application to the relevant authorities and ensuring timely processing.</li>
          <li><strong>Step 4:</strong> Delivering the approved licenses and helping with compliance management.</li>
        </ul>

        <h2>Get Your Business Licensed Today</h2>
        <p>
          Having the right licenses is essential for the smooth functioning of your business. Whether you need GST registration, MSME certification, or other business licenses, we are here to assist. Contact us today to ensure your business is legally compliant and ready to thrive.
        </p>
      </div>
    </div>
  );
};

export default BusinessLicenses;

import React from 'react';
import './ServiceDetails.css';

const AccountingService = () => {
  return (
    <div className="service-page">
      <div className="service-header">
        <h1>Accounting & Bookkeeping</h1>
      </div>
      <div className="service-content">
        <p>
          Accurate and efficient accounting is critical for the success of any business. At Pramodni & Co., we offer comprehensive accounting and bookkeeping services tailored to meet the specific needs of your business. Our team ensures that your financial records are up-to-date, accurate, and in compliance with regulatory requirements.
        </p>

        <h2>Our Accounting & Bookkeeping Services Include:</h2>
        <ul>
          <li><strong>Financial Statement Preparation:</strong> We assist in preparing accurate and compliant financial statements, including profit and loss statements, balance sheets, and cash flow statements.</li>
          <li><strong>Bookkeeping:</strong> We offer end-to-end bookkeeping services, managing your day-to-day financial transactions including accounts payable, accounts receivable, payroll processing, and bank reconciliations.</li>
          <li><strong>Budgeting & Forecasting:</strong> Our experts help you plan for the future by preparing detailed budgets and financial forecasts, enabling you to make informed business decisions.</li>
          <li><strong>Ledger Maintenance:</strong> Proper maintenance of ledgers is crucial for financial clarity. We ensure that your ledgers are up-to-date, organized, and accurate.</li>
          <li><strong>Tax-Ready Financials:</strong> We ensure that your financial records are prepared in a way that facilitates easy tax filing, making tax season stress-free and efficient.</li>
          <li><strong>Audit Support:</strong> In case of an audit, we provide support and ensure that your records are in order, making the audit process as smooth as possible.</li>
          <li><strong>Customized Reporting:</strong> We provide customized financial reports based on your specific business needs, helping you track financial performance and make data-driven decisions.</li>
        </ul>

        <h2>Why Choose Us for Accounting & Bookkeeping?</h2>
        <ul>
          <li><strong>Experienced Accountants:</strong> Our team comprises seasoned accountants who understand the complexities of accounting and bookkeeping for businesses across industries.</li>
          <li><strong>Cost-Effective Solutions:</strong> We offer flexible and affordable services that are tailored to your business needs, ensuring you get maximum value without overspending.</li>
          <li><strong>Compliance:</strong> We ensure that your financial records are always in compliance with the latest accounting standards and regulations, reducing the risk of penalties and legal issues.</li>
          <li><strong>Accuracy & Reliability:</strong> Our team is committed to maintaining the highest standards of accuracy and reliability in every aspect of our accounting services.</li>
        </ul>

        <h2>How Our Accounting Process Works:</h2>
        <ul>
          <li><strong>Step 1:</strong> Initial consultation to understand your business's financial needs and current bookkeeping practices.</li>
          <li><strong>Step 2:</strong> Set up or streamline your accounting system to ensure efficient financial management.</li>
          <li><strong>Step 3:</strong> Ongoing bookkeeping and preparation of financial statements, budgets, and forecasts.</li>
          <li><strong>Step 4:</strong> Regular review meetings to provide insights into your financial performance and recommendations for improvement.</li>
        </ul>

        <h2>Contact Us for Accounting & Bookkeeping</h2>
        <p>
          Whether you're a small business or a large corporation, we have the accounting solutions to meet your needs. Get in touch with us today to discuss how we can help manage your financials effectively.
        </p>
      </div>
    </div>
  );
};

export default AccountingService;

import React from 'react';
import './ServiceDetails.css';

const CompanyIncorporation = () => {
  return (
    <div className="service-page">
      <div className="service-header">
        <h1>Company Incorporation</h1>
      </div>
      <div className="service-content">
        <p>
          Starting a new business is an exciting journey, and getting your company registered is the first critical step. At Pramodni & Co., we offer comprehensive company incorporation services that make the process simple, efficient, and hassle-free. We help you navigate through the legal and regulatory requirements, so you can focus on building your business.
        </p>

        <h2>Our Company Incorporation Services Include:</h2>
        <ul>
          <li><strong>Private Limited Company Registration:</strong> We assist in registering private limited companies, ensuring that all the necessary legal formalities and documentation are in place.</li>
          <li><strong>One-Person Company Registration:</strong> For entrepreneurs who want to establish a single-member company, we help register one-person companies quickly and efficiently.</li>
          <li><strong>Limited Liability Partnership (LLP) Registration:</strong> LLP registration is ideal for professionals and small businesses. We ensure smooth registration, including compliance with the LLP Act, 2008.</li>
          <li><strong>Partnership Firm Registration:</strong> We help you register a partnership firm under the Indian Partnership Act, 1932, ensuring all partners' rights and obligations are well defined.</li>
          <li><strong>Sole Proprietorship Registration:</strong> For individuals who wish to run a business as sole proprietors, we assist with all the necessary steps to establish a legal and operational structure.</li>
          <li><strong>Section 8 Company Registration:</strong> We specialize in registering non-profit organizations under Section 8 of the Companies Act, 2013, including all related legalities and approvals.</li>
        </ul>

        <h2>Why Choose Us for Company Incorporation?</h2>
        <ul>
          <li><strong>End-to-End Support:</strong> We handle everything from name reservation, Digital Signature Certificate (DSC) application, Director Identification Number (DIN) application, drafting MOA and AOA, to obtaining the Certificate of Incorporation (COI).</li>
          <li><strong>Expert Guidance:</strong> Our experienced professionals provide expert advice and guidance, ensuring your company is set up in compliance with legal norms and best practices.</li>
          <li><strong>Customized Solutions:</strong> We offer incorporation services tailored to your specific business needs, whether you're a small startup or an established enterprise looking to expand.</li>
          <li><strong>Faster Registration:</strong> We streamline the registration process to ensure that your business gets incorporated quickly and without unnecessary delays.</li>
          <li><strong>Post-Incorporation Support:</strong> We offer support with post-incorporation compliance such as PAN & TAN application, opening a bank account, and tax registration.</li>
        </ul>

        <h2>Our Company Incorporation Process:</h2>
        <ul>
          <li><strong>Step 1:</strong> Consultation to understand your business goals and help select the appropriate legal structure for incorporation.</li>
          <li><strong>Step 2:</strong> Collection of necessary documents, such as identity and address proof of directors, and drafting of required legal documents (MOA, AOA).</li>
          <li><strong>Step 3:</strong> Filing of the incorporation form with the Ministry of Corporate Affairs (MCA) and obtaining the Certificate of Incorporation (COI).</li>
          <li><strong>Step 4:</strong> Post-incorporation services such as tax registration, company seal, and share certificates.</li>
        </ul>

        <h2>Get Started with Your Company Incorporation</h2>
        <p>
          Whether you're setting up a private limited company, LLP, or any other form of business entity, we're here to guide you through the entire process. Contact us today to begin your journey of forming a legally compliant business.
        </p>
      </div>
    </div>
  );
};

export default CompanyIncorporation;

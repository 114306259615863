import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Services from './components/Services';
import IncomeTax from './components/services/income-tax'
import GST from './components/services/gst-registration'
import Accounting from './components/services/accounting'
import CompanyReg from './components/services/company-registration'
import Audit from './components/services/audit'
import BusinessLicences from './components/services/business-licenses'
import TDS from './components/services/tds'
import BusinessValuation from './components/services/business-valuation'
import DSC from './components/services/digital-signature-certificates'
import ROC from './components/services/roc'
import Trademark from './components/services/trademark-registration'
import Compliance from './components/services/compliance-by-company'
import ISO from './components/services/iso-registration'
import About from './components/About';
import Team from './components/Team';
import Contact from './components/Contact';
import './App.css';

function App() {
    return (
        <Router>
            <div id="root">
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/services/income-tax" element={<IncomeTax />} />
                    <Route path="/services/gst-registration" element={<GST />} />
                    <Route path="/services/accounting" element={<Accounting />} />
                    <Route path='/services/company-registration' element={<CompanyReg />} />
                    <Route path="/services/audit" element={<Audit />} />
                    <Route path="/services/business-licenses" element={<BusinessLicences />} />
                    <Route path="/services/tds" element={<TDS />} />
                    <Route path="/services/business-valuation" element={<BusinessValuation />} />
                    <Route path="/services/digital-signature-certificates" element={<DSC />} />
                    <Route path="/services/roc" element={<ROC />} />
                    <Route path="/services/trademark-registration" element={<Trademark />} />
                    <Route path="/services/compliance-by-company" element={<Compliance />} />
                    <Route path="/services/iso-registration" element={<ISO />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;

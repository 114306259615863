import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Contact.css';

// {
//     temp_id : template_b22isi2
//     serv_id : service_jcqzjgn
//     pub_key : 60qfo50aKATkfwGQV
// }


emailjs.init("PtNj_lhWAsxZPg0H_");
const phoneNumber = "919953428224"; 
const message = "Hello, I would like to know more about your services";

const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contactNumber: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Send email using EmailJS
        emailjs.send('service_jcqzjgn', 'template_b22isi2', formData, '60qfo50aKATkfwGQV')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Message sent successfully!');
                setFormData({ name: '', email: '', message: '' });
            }, (err) => {
                console.log('FAILED...', err);
                alert('Failed to send message, please try again later.');
            });
    };

    return (
        <section className="contact-section">
            <div className="form-container">
                <h2>Contact Us</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Your Name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Your Email"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="contactNumber">Contact Number:</label>
                        <input
                            type="tel"
                            id="contactNumber"
                            name="contactNumber"
                            value={formData.contactNumber}
                            onChange={handleChange}
                            placeholder="Your Contact Number"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Your Message"
                            required
                        ></textarea>
                    </div>
                    <button type="submit">Send Message</button>
                </form>

                {/* Social Media Icons Section */}
                <div className="social-icons">
                    <a href="https://www.linkedin.com/company/pramodni-co/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} size="2x" />
                    </a>
                    <a href="mailto:ca@pramodni.co.in" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faEnvelope} size="2x" />
                    </a>
                    <a href={whatsappURL} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                    </a>
                    <a href="https://www.instagram.com/pramodni_co/profilecard/?igsh=bGQ1bWQ4Z3JlaGk=" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} size="2x" />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Contact;

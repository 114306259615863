import React from 'react';
import './ServiceDetails.css';

const GSTService = () => {
  return (
    <div className="service-page">
      <div className="service-header">
        <h1>GST Registration & Return Filing</h1>
      </div>
      <div className="service-content">
        <p>
          At Pramodni & Co., we provide comprehensive assistance with GST registration and return filing. Our team helps businesses navigate the complexities of GST regulations, ensuring compliance while minimizing administrative burden. We aim to make the process seamless, allowing you to focus on your business.
        </p>

        <h2>Our GST Services Include:</h2>
        <ul>
          <li><strong>GST Registration:</strong> We assist businesses in registering for GST in compliance with government regulations. Our experts ensure that you obtain the right GST type (regular, composite) based on your business model.</li>
          <li><strong>GST Return Filing:</strong> Timely filing of GST returns is crucial to avoid penalties. We manage your monthly, quarterly, and annual returns with accurate documentation.</li>
          <li><strong>Input Tax Credit (ITC) Optimization:</strong> We help you maximize your input tax credit by ensuring that your purchases and expenses are documented properly, reducing your tax liability.</li>
          <li><strong>GST Audit & Compliance:</strong> We assist in GST audits and ensure full compliance with the latest GST rules. Our team helps you prepare for and respond to audits by the tax authorities.</li>
          <li><strong>GST Amendments & Cancellations:</strong> Whether you need to update your GST registration details or cancel your registration, we provide end-to-end assistance in making these changes smoothly.</li>
          <li><strong>GST Refunds:</strong> If your business qualifies for a GST refund, we handle the complete process, ensuring that all refund claims are filed and tracked accurately.</li>
          <li><strong>Consultation on GST Rules & Updates:</strong> GST laws are dynamic and subject to frequent changes. Our team stays updated with the latest GST amendments, offering consultations to help you comply with the most recent rules.</li>
        </ul>

        <h2>Why Choose Us for GST Services?</h2>
        <ul>
          <li><strong>Expert Guidance:</strong> Our GST experts have in-depth knowledge of GST regulations and will guide you through each step of the process.</li>
          <li><strong>Timely Filing:</strong> We ensure that your GST returns are filed on time, helping you avoid late fees and penalties.</li>
          <li><strong>Accuracy:</strong> Our team ensures that all data provided is accurate and that your filings are error-free.</li>
          <li><strong>Cost-Effective Solutions:</strong> We offer affordable GST services tailored to your business needs, helping you stay compliant without breaking the bank.</li>
        </ul>

        <h2>How It Works:</h2>
        <ul>
          <li><strong>Step 1:</strong> Initial consultation to understand your business structure and GST requirements.</li>
          <li><strong>Step 2:</strong> Collection of necessary documents and details for GST registration or return filing.</li>
          <li><strong>Step 3:</strong> Registration or filing of returns, with regular follow-up to ensure smooth processing.</li>
          <li><strong>Step 4:</strong> Ongoing support for amendments, audits, and consultation on GST-related matters.</li>
        </ul>

        <h2>Contact Us for GST Services</h2>
        <p>
          For hassle-free GST registration, return filing, and compliance, get in touch with our team. We are here to help you manage your GST obligations efficiently and effectively.
        </p>
      </div>
    </div>
  );
};

export default GSTService;

import React from 'react';
import './Team.css';

const Team = () => {
    const teamMembers = [
        {
            name: 'CA Pramodni',
            role: 'Chartered Accountant',
            imageUrl: '/images/member1.jpeg',
        },
        {
            name: 'Amit Kumar Jha',
            role: 'Manager',
            imageUrl: '/images/member2.jpeg',
        },
        {
            name: 'Ravi Nandan',
            role: 'Senior Associate',
            imageUrl: '/images/member3.jpeg',
        },
        {
            name: 'Rozy Jha',
            role: 'Senior Associate',
            imageUrl: '/images/member4.jpeg',
        },
    ];

    return (
        <section className="team-section">
            <div className="team-intro">
                <h2>Meet Our Team</h2>
                <p>
                    Our dedicated team of professionals brings a wealth of knowledge and experience
                    in accounting, tax advisory, auditing, and financial consulting. We work together
                    to provide our clients with personalized and expert solutions to their financial needs.
                </p>
            </div>
            
            <div className="team-grid">
                {teamMembers.map((member, index) => (
                    <div className="team-member" key={index}>
                        <img src={member.imageUrl} alt={member.name} className="team-member-image" />
                        <h3>{member.name}</h3>
                        <p>{member.role}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Team;

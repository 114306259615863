import React from 'react';
import './ServiceDetails.css';

const BusinessValuation = () => {
  return (
    <div className="service-page">
      <div className="service-header">
        <h1>Business Valuation Services</h1>
      </div>
      <div className="service-content">
        <p>
          Business valuation is a critical process for any company, whether you are planning to sell, seeking investment, or need to assess the value of your business for any reason. Our expert team provides accurate and comprehensive business valuation services to help you understand the worth of your business and make informed decisions.
        </p>

        <h2>Our Business Valuation Services Include:</h2>
        <ul>
          <li><strong>Market Approach:</strong> We analyze comparable sales and market conditions to determine your business’s value based on what similar companies have sold for.</li>
          <li><strong>Income Approach:</strong> We project future income and discount it to present value, giving you a realistic valuation based on your business’s earnings potential.</li>
          <li><strong>Asset-Based Approach:</strong> We evaluate your company's assets and liabilities to arrive at a valuation based on the net asset value.</li>
          <li><strong>Valuation for Mergers & Acquisitions:</strong> We assist in determining the value of your business for potential mergers or acquisitions, ensuring you get the best deal.</li>
          <li><strong>Financial Reporting:</strong> Our team helps prepare valuation reports for financial statements and compliance with accounting standards.</li>
        </ul>

        <h2>Why Choose Our Business Valuation Services?</h2>
        <ul>
          <li><strong>Expert Analysis:</strong> Our experienced professionals use proven methodologies to provide accurate and reliable business valuations.</li>
          <li><strong>Tailored Approach:</strong> We understand that every business is unique; hence, we tailor our valuation process to meet your specific needs.</li>
          <li><strong>Confidentiality:</strong> We prioritize your privacy and handle all information with the utmost confidentiality.</li>
          <li><strong>Timely Delivery:</strong> We commit to delivering our valuation reports within agreed timelines, ensuring you have the information you need when you need it.</li>
        </ul>

        <h2>Our Process:</h2>
        <ul>
          <li><strong>Step 1:</strong> Initial Consultation: We discuss your needs and gather relevant information about your business.</li>
          <li><strong>Step 2:</strong> Data Collection: We collect necessary financial data, industry benchmarks, and market conditions.</li>
          <li><strong>Step 3:</strong> Valuation Analysis: Our team performs a thorough analysis using the chosen valuation method.</li>
          <li><strong>Step 4:</strong> Report Preparation: We prepare a comprehensive valuation report, detailing our findings and methodology.</li>
          <li><strong>Step 5:</strong> Review & Finalization: We review the report with you and make any necessary adjustments before finalizing it.</li>
        </ul>

        <h2>Get Your Business Valuation Today</h2>
        <p>
          Understanding the value of your business is crucial for strategic decision-making. Contact us today to schedule your business valuation!
        </p>
      </div>
    </div>
  );
};

export default BusinessValuation;

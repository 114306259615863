import React from 'react';
import './ServiceDetails.css';

const TDSReturn = () => {
  return (
    <div className="service-page">
      <div className="service-header">
        <h1>TDS Return Filing</h1>
      </div>
      <div className="service-content">
        <p>
          TDS (Tax Deducted at Source) is a crucial part of the tax regime in India, ensuring tax collection at the source of income generation. Filing TDS returns accurately and on time is mandatory for businesses and individuals liable for TDS deductions. We provide comprehensive TDS return filing services to ensure that your business stays compliant with government regulations.
        </p>

        <h2>Our TDS Return Filing Services Include:</h2>
        <ul>
          <li><strong>Preparation of TDS Returns:</strong> We help in preparing TDS returns for various types of payments, such as salary, contractor payments, rent, interest, and more.</li>
          <li><strong>Online TDS Filing:</strong> We assist in e-filing TDS returns through the official government portals, ensuring accuracy and timely submission.</li>
          <li><strong>Corrections in TDS Returns:</strong> If any mistakes or discrepancies arise in the TDS returns, we provide correction services to rectify errors.</li>
          <li><strong>Quarterly Filing:</strong> Our team ensures that your quarterly TDS returns are filed on time and in accordance with applicable deadlines.</li>
          <li><strong>Form 16 & 16A Generation:</strong> We assist in generating Form 16 for salary and Form 16A for non-salary deductions, which are mandatory to be provided to the deductees.</li>
        </ul>

        <h2>Why Choose Our TDS Return Filing Services?</h2>
        <ul>
          <li><strong>Timely Compliance:</strong> We ensure that your TDS returns are filed on time, avoiding penalties and interest due to late submission.</li>
          <li><strong>Accuracy:</strong> Our professionals take care of all aspects of TDS return filing, minimizing the chances of errors or discrepancies in the filing process.</li>
          <li><strong>Expert Support:</strong> We provide expert assistance to ensure you comply with all TDS provisions, including complex tax structures and payments.</li>
          <li><strong>Hassle-Free Process:</strong> We handle all the documentation, filing, and coordination with the authorities so that you can focus on your business.</li>
        </ul>

        <h2>How Our Process Works:</h2>
        <ul>
          <li><strong>Step 1:</strong> Gathering the necessary details, such as payments made and taxes deducted, along with related documents.</li>
          <li><strong>Step 2:</strong> Preparing the TDS returns and ensuring compliance with current tax laws and regulations.</li>
          <li><strong>Step 3:</strong> Filing the returns online with the Income Tax Department within the prescribed deadlines.</li>
          <li><strong>Step 4:</strong> Providing the necessary forms (Form 16/16A) to the deductees for their records.</li>
        </ul>

        <h2>Stay Compliant with TDS Filing</h2>
        <p>
          Filing TDS returns on time is essential to avoid penalties and ensure compliance with tax laws. Let us take the burden off your shoulders by managing the TDS return filing process for your business. Contact us today to get started with your TDS filing!
        </p>
      </div>
    </div>
  );
};

export default TDSReturn;

import React from 'react';
import './ServiceDetails.css';

const DigitalSignatureCertificates = () => {
  return (
    <div className="service-page">
      <div className="service-header">
        <h1>Digital Signature Certificates</h1>
      </div>
      <div className="service-content">
        <p>
          In today's digital world, securing your online transactions and communications is more important than ever. A Digital Signature Certificate (DSC) ensures the authenticity and integrity of your documents, allowing you to conduct business with confidence. Our firm provides comprehensive services for obtaining and managing digital signature certificates, ensuring your digital communications are secure and compliant with regulations.
        </p>

        <h2>Our Digital Signature Certificate Services Include:</h2>
        <ul>
          <li><strong>Issuance of Digital Signature Certificates:</strong> We assist you in obtaining the right type of DSC for your needs, whether for individuals or businesses.</li>
          <li><strong>Renewal of Digital Signature Certificates:</strong> We provide services for the timely renewal of your digital signatures to ensure uninterrupted access to secure digital transactions.</li>
          <li><strong>Verification Services:</strong> Our team helps verify digital signatures for documents, ensuring authenticity and trustworthiness.</li>
          <li><strong>Support for Multiple Applications:</strong> We provide DSCs suitable for e-filing income tax returns, GST registration, and other government applications.</li>
        </ul>

        <h2>Why Choose Our Digital Signature Certificate Services?</h2>
        <ul>
          <li><strong>Expert Guidance:</strong> Our professionals have extensive knowledge of the digital signature ecosystem and can guide you through the entire process.</li>
          <li><strong>Quick Turnaround:</strong> We pride ourselves on our efficient processes, ensuring you receive your digital signature certificate promptly.</li>
          <li><strong>Security and Compliance:</strong> We ensure that all your digital signatures comply with regulatory standards and provide secure solutions for your transactions.</li>
          <li><strong>Ongoing Support:</strong> We offer continuous support for any issues related to your digital signature certificates, ensuring smooth operations for your business.</li>
        </ul>

        <h2>Our Process:</h2>
        <ul>
          <li><strong>Step 1:</strong> Consultation: We understand your requirements and recommend the best type of digital signature certificate for you.</li>
          <li><strong>Step 2:</strong> Document Collection: We assist in gathering the necessary documents and information needed for the application.</li>
          <li><strong>Step 3:</strong> Application Submission: Our team submits the application to the certifying authority on your behalf.</li>
          <li><strong>Step 4:</strong> Certificate Issuance: Once approved, we provide you with your digital signature certificate and guide you on its use.</li>
          <li><strong>Step 5:</strong> Post-Issuance Support: We offer ongoing support for certificate renewal and verification.</li>
        </ul>

        <h2>Secure Your Digital Transactions Today</h2>
        <p>
          Protect your online transactions and communications with our reliable digital signature certificate services. Contact us today to get started!
        </p>
      </div>
    </div>
  );
};

export default DigitalSignatureCertificates;
